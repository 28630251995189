<template>

  <div class="user-profile">
  
    <br /><br />
          
          <center>
            <b-avatar
              size="150"
              variant="light-primary"
              :text="$user.brand_name ? $user.brand_name.charAt(0) : $user.name.charAt(0)"
              badge
              
              class="badge-minimal"
              badge-variant="success"
            />
          </center>
          <br />
          <div class="">
            <p class="user-name mb-0">
              <span v-if="$user.brand_name">{{$user.brand_name}}</span>
              <span v-else>{{$user.name}} {{$user.surname}}</span>
             
            </p>
            <!-- <span class="user-status">Admin</span> -->
          </div>
          <br /><br />
          <div class="logout-link">
          <a link-class="d-flex align-items-center">
            <feather-icon @click="logout()"
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
                />
                <span>Logout</span>
              </a>
          </div>
          

        <b-dropdown-divider />

       

      </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {Auth} from "@simb2s/senseye-sdk"
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async logout()
    {
      await Auth.logout()
      document.location.reload(); 
    },
  }
}
</script>

<style scoped>
.user-name
{
  font-weight: 500;
  font-size:20px;
  text-align: center;
}
.user-profile
{
  display:block;
  width:100%;
  
}
.logout-link
{
  display: block;
  position: fixed;
  bottom:40px;
  z-index:3;
}
</style>