export default [

  {
    title: 'Orders',
    route: 'home',
    icon: 'home',
  },

  // {
  //   title: 'Clients & Scans',
  //   route: 'scans',
  //   icon: 'SmileIcon',
  // },
 
  

]
